
import { defineComponent } from 'vue';
import {LocationAsPath} from "vue-router"
import {useStore} from "vuex"
import {key} from "@/store"

export default defineComponent({
  name: 'NavigationApp',
  components: {
  },

  data() {
    return {
      date: (() => {
        const date = new Date()
        return date.toLocaleDateString("fr-FR", {
          weekday:  "long",
          day: "numeric",
          month:    "long",
          year:   "numeric",
        })
      })(),

      dateMobile: (() => {
        const date = new Date()
        const day   = date.toLocaleDateString("fr-FR", {day:    "2-digit",})
        const month = date.toLocaleDateString("fr-FR", {month:  "2-digit",})
        const year  = date.toLocaleDateString("fr-FR", {year:   "2-digit",})

        return `${day}.${month}.${year}`
      })(),

      hide: false,

      store: useStore(key)
    }
  },

  mounted() {
    const scrollValueToHideNav = 100;
    let holdScrollValue = 0

    window.addEventListener("scroll", (e) => {
      if      (window.scrollY > scrollValueToHideNav && holdScrollValue < window.scrollY) this.hide = true
      else if (holdScrollValue > window.scrollY && window.scrollY > scrollValueToHideNav) this.hide = false
      holdScrollValue = window.scrollY
    })
  },

  methods: {
    toggleMenu() {
      this.store.commit("toggleMenu")
    }
  },

});
