import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cd58535"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "v-app v-app__loader"
}
const _hoisted_2 = {
  key: 1,
  class: "v-app"
}
const _hoisted_3 = {
  key: 0,
  class: "v-app__nav"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_transition = _resolveComponent("page-transition")!
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")!
  const _component_navigation_app = _resolveComponent("navigation-app")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_footer = _resolveComponent("app-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "transition" }, {
      default: _withCtx(() => [
        (_ctx.store.state.showPageTransition)
          ? (_openBlock(), _createBlock(_component_page_transition, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_vue_easy_lightbox, {
      visible: _ctx.easyLightBoxImage !== null,
      imgs: _ctx.easyLightBoxImage,
      onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.store.commit('removeEasyLightBoxImage'))),
      onContextmenu: _cache[1] || (_cache[1] = ($event: any) => (_ctx.preventContextMenu($event)))
    }, null, 8, ["visible", "imgs"]),
    (_ctx.store.getters.appLockStatus === 'waiting')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.isLogView)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_navigation_app)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view),
          (!_ctx.isLogView)
            ? (_openBlock(), _createBlock(_component_app_footer, { key: 1 }))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}