
import { defineComponent } from 'vue';
import NavigationApp from "@/components/NavigationApp.vue"
import AppFooter from "@/components/AppFooter.vue";
import {useStore} from "vuex"
import {key} from "@/store"
import PageTransition from "@/components/PageTransition.vue"
import VueEasyLightbox from 'vue-easy-lightbox'

export default defineComponent({
  name: 'App',

  data() {
    return {
      store: useStore(key),
    }
  },

  components: {
    PageTransition,
    AppFooter,
    NavigationApp,
    VueEasyLightbox,
  },

  methods: {
    preventContextMenu(e: MouseEvent) {
      e.preventDefault()
    }
  },

  computed: {
    isLogView(): boolean {
      return this.$route.name === 'Log'
    },

    easyLightBoxImage(): string | null {
      return this.store.state.easyLightBoxImage
    }
  }

});
